import axios from 'axios'
import Echo from 'laravel-echo'
import store from '@/store'

global.Pusher = require('pusher-js')

const echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_SOKETI_APP_KEY,
  wsHost: process.env.VUE_APP_SOKETI_HOST,
  wsPort: process.env.VUE_APP_SOKETI_PORT,
  wssPort: process.env.VUE_APP_SOKETI_PORT,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  authorizer: (channel) => {
    return {
      authorize (socketId, callback) {
        const orgCode = store.getters['liffChatroom/orgCode']
        const token = store.getters['liffChatroom/token']
        const authEndpoint = `${process.env.VUE_APP_API_BASE_URL}/${orgCode}/liff/broadcasting/auth`

        axios.post(authEndpoint, {
          socket_id: socketId,
          channel_name: channel.name,
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((response) => callback(false, response.data))
        .catch((error) => callback(true, error))
      }
    }
  },
})

export default echo
